<template>
    <div>
        <div class="card col-lg-12 col-md-12 col-sm-12" style="min-height: 20em">
            <div>
                <h4 class="my-4 font-weight-bold">
                    Liste des authorizations
                </h4>
            </div> 
            <b-container>
                <b-overlay :show="showOverlayTable" rounded="sm">
                    <div class="mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap" style="height: 40em; overflow-y: auto; overflow-x: hidden;">
                        <b-table  sticky-header="90%" striped hover responsive :items="permissions" :fields="[
                                { key: 'index', label: 'N°', sortable: true  },
                                { key: 'codePrivilege', label: 'Code', sortable: true },
                                { key: 'libelle', label: 'Libellé', sortable: true },
                                { key: 'description', label: 'Description', sortable: true },
                                { key: 'action', label: 'Action' },
                            ]" style="min-height: 10em " class="table table-bordered"> 
                            <template #cell(index)="data">{{ ++data.index }}</template>
                            <template #cell(codePrivilege)="data"><h5><b-badge>{{ data.item.codePrivilege }}</b-badge></h5></template>
                            <template #cell(libelle)="data">{{ data.item.libellePrivilege }}</template>
                            <template #cell(description)="data">{{ data.item.description }}</template>
                            <template #cell(action)="row">
                                <div style = "display: flex">
                                    <b-button size="sm" variant ="light" @click.prevent="deletePrivilegeUser(row.item)" class="ml-2"><i style="width: 100%" class="ik ik-delete"></i></b-button>
                                </div>  
                            </template>
                        </b-table>
                    </div>
                </b-overlay>
            </b-container>
        </div>
    </div>
</template>
<script>
export default {
    name:'users',
      data: () => ({
          showOverlayTable: true,
            permissions:[
                {
                    numero : 1,
                    libelle:'list-user',
                    description:'Droit de voir la liste des Utilisateurs',
                },
                {
                    numero : 2,
                    libelle:'add_user',
                    description:'Droit de pouvoir ajouter un utilisateur',
                },
                {
                    numero : 3,
                    libelle:'edit_user',
                    description:'Droit de pouvoir modifier les informations d\'un utilisateur',
                },
                {
                    numero : 4,
                    libelle:'delete_user',
                    description:'Droit de pouvoir supprimer un utilisateur',
                },
                {
                    numero : 5,
                    libelle:'list_roles',
                    description:'Droit de pouvoir voir la liste des roles',
                },
                {
                    numero : 6,
                    libelle:'add_role',
                    description:'Droit de pouvoir ajouter un role',
                },
                {
                    numero : 7,
                    libelle:'edit_role',
                    description:'Droit de pouvoir modifier un role',
                },
                {
                    numero : 8,
                    libelle:'delete_role',
                    description:'Droit de pouvoir supprimer un role',
                }
            ],

            
      }),
      methods: {
          roleDetails(role){
              console.log('role', role);
          },
          editRole(role){
              console.log('role', role)
          },
          deleteRole(role){
              console.log('role', role)
          },
          getPrivileges() {
            this.showOverlayTable = true;
            axios.get('constants/privileges').then(response => response.result || []).then(result => {
                this.permissions = result
                this.showOverlayTable = false
            })
         },
      },
      mounted(){
        this.getPrivileges();
        /*setTimeout(() => {
            this.showOverlayTable= false;
        }, 2000)*/

    },
}
</script>
<style scoped>
    .icon-list-table{
        width: 4em;
         height: 4em; 
         background: red;
         position: absolute;
         top:-5%;
    }
    .text-list{
        position: absolute;
        top: 5px;
        left: 8%;
    }
    .actions-buttons-group{
        position: absolute;
        top: 5em;
        left: 8%
    }

</style>