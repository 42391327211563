var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card col-lg-12 col-md-12 col-sm-12",staticStyle:{"min-height":"20em"}},[_vm._m(0),_c('b-container',[_c('b-overlay',{attrs:{"show":_vm.showOverlayTable,"rounded":"sm"}},[_c('div',{staticClass:"mt-1 pr-2 w-100 d-flex justify-content-around flex-wrap",staticStyle:{"height":"40em","overflow-y":"auto","overflow-x":"hidden"}},[_c('b-table',{staticClass:"table table-bordered",staticStyle:{"min-height":"10em"},attrs:{"sticky-header":"90%","striped":"","hover":"","responsive":"","items":_vm.permissions,"fields":[
                            { key: 'index', label: 'N°', sortable: true  },
                            { key: 'codePrivilege', label: 'Code', sortable: true },
                            { key: 'libelle', label: 'Libellé', sortable: true },
                            { key: 'description', label: 'Description', sortable: true },
                            { key: 'action', label: 'Action' },
                        ]},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(_vm._s(++data.index))]}},{key:"cell(codePrivilege)",fn:function(data){return [_c('h5',[_c('b-badge',[_vm._v(_vm._s(data.item.codePrivilege))])],1)]}},{key:"cell(libelle)",fn:function(data){return [_vm._v(_vm._s(data.item.libellePrivilege))]}},{key:"cell(description)",fn:function(data){return [_vm._v(_vm._s(data.item.description))]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticStyle:{"display":"flex"}},[_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){$event.preventDefault();return _vm.deletePrivilegeUser(row.item)}}},[_c('i',{staticClass:"ik ik-delete",staticStyle:{"width":"100%"}})])],1)]}}])})],1)])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"my-4 font-weight-bold"},[_vm._v(" Liste des authorizations ")])])
}]

export { render, staticRenderFns }